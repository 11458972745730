:root {
  --so-primary: #002a7f;
  --so-primary-subtle: #4852af;
  --so-primary-thick: #000152;
  --so-secondary: #a3bd36;
  --so-secondary-subtle: #d7f068;
  --so-secondary-soft-thick: #9db345;
  --so-secondary-thick: #718d00;
  --so-neuter: #c5c5c5;
  --so-neuter-subtle: rgb(197 197 197 / 25%);
  --so-neuter-thick: #272d3b;
  --so-danger: #f44336;
  --so-danger-subtle: #ff7961;
  --so-danger-thick: #ba000d;
  --so-warning: #fdd835;
  --so-warning-subtle: #ffff6b;
  --so-warning-thick: #c6a700;
  --so-success: #64dd17;
  --so-success-icon: #64dd17;
  --so-success-subtle: #9cff57;
  --so-success-thick: #1faa00;
  --so-pink: #ef00b7;
  --so-purple: #570df8;
  --so-orange: #f38c15;
  --so-gray: #e7e7e7;
  --so-gray-soft: #f9f9f9;
  --so-gray-subtle: #f0f0f0;
  --so-gray-thick: #6c757d;
  --so-gray-thick-soft: #b8b8b8;
  --so-gray-thick-hard: #707070;
  --so-green: #29a69a;
  --so-green-subtle: #2aa69a;
  --so-info: #00a3eb;
  --so-info-subtle: #21a7e2;
  --so-white: #ffffff;
  --so-black: #000000;
  --so-blue: #002A7F;
  --so-blue-light: #41b9f0;
  --so-yellow-subtle: #ffffc4;
  --so-striped: #EDEDED;
  --so-striped-thick: #d9d9d9;
  --so-border-left: #e1e1e1;
  --so-green-whatsapp: #D9FDD3;
  --so-light-white: #F4F4F4;
  --so-light-black: #44444F;
  --so-soft-blue: #8095bf;
  

  --so-disabled-input: var(--so-light-white);
  --so-placeholder-input: var(--so-neuter);

  //sidebar
  --so-sidebar: var(--so-primary);
  --so-sidebar-items: var(--so-soft-blue);
  --so-hover-sidebar: var(--so-white);

  // LIGHT
  --so-primary-light: rgb(0 42 127 / 15%);
  --so-secondary-light: rgb(163 189 54 / 15%);
  --so-danger-light: rgb(244 67 54 / 15%);
  --so-warning-light: rgb(253 216 53 / 15%);
  --so-success-light: rgb(100 221 23 / 15%);
  --so-neuter-light: #f6f6f6;
  --so-light-grey: rgba(246, 246, 246, 0.9);
  --so-gradient-percentage: 80%;

  // OPACITY
  --so-success-subtle-opacity-50: rgb(156 255 87 / 50%);
  --so-danger-subtle-opacity-50: rgb(255 121 97 / 50%);
  --so-warning-subtle-opacity-50: rgb(255 255 107 / 50%);
  --so-primary-opacity-50: rgba(0, 42, 127, 0.5);
  --so-secondary-opacity-50: rgba(163, 189, 54, 0.5);
  --so-warning-thick-opacity-50: rgba(198, 167, 0, 0.5);
  --so-danger-opacity-50: rgba(244, 67, 54, 0.5);
  --so-black-opacity-50: var(--background-invert);
  --so-transparent: transparent;

  --background: #f6f6f6;
  --background-light: #f8f8f8;
  --background-card: #ffffff;
  --background-card-ia: #f6f6f6;
  --background-invert: #0d0f11;
  --color-icon:#002a7f;
  --color-icon-inverse:#002a7f;
  --text: var(--so-black);
  --box-shadow: 0px 3px 6px rgba(197, 197, 197, 0.5019607843);
  --box-shadow-no-bottom: 0px 0px 6px rgba(197, 197, 197, 0.5019607843);
  --box-shadow-nav-var: 10px 10px 20px 15px rgba(246, 246, 246, 0.58);
  --box-shadow-intern-cards: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --navbar-bg: var(--so-white);

  // Badges
  --backgound-badge-success: #1faa001a;
  --text-badge-success:var(--so-success-thick);
  --backgound-badge-warning: #c6a7001a;
  --text-badge-warning: var(--so-warning-thick);
  --backgound-badge-danger: #f443361a;
  --text-badge-danger: var(--so-danger);
  --backgound-badge-primary: #002a7f1a;
  --text-badge-primary: var(--so-primary);
  --backgound-badge-black: transparent;
  --text-badge-black: var(--so-black);
  --backgound-badge-blue-light: #002a7f1a;
  --text-badge-black-blue-light: var(--so-white);
  --card-border-medium: var(--background-card);
  // Forms
  --text-input: var(--text-input);
  --background-input: var(--so-white);
  --card-border: var(--so-neuter);
  --multi-select: var(--backgound-badge-success);
  --multi-select-text: var(--text-badge-success);
  --so-select-label: #505665;

  // Popovers
  --so-popover-bg: #EBEEF5;

  // Brands
  --amazon-orange: #ff9900;
  --deepgram-green: #00f89c;
  --azure-blue: #0078d4;
  --gm-blue-text: #158dcd;
  // --whatsapp-primary: #007e67;
  --whatsapp-primary: #f1f2f6;
  --whatsapp-secondary: #ffffff;
  --whatsapp-subtle: #25d366;
  --whatsapp-bg: #efece2;
  --whatsapp-received: #ffffff;
  --whatsapp-received-system: #ffffffa0;
  --whatsapp-bar-actions: #FEFEFE;
  --whatsapp-sended: #dcf8c6;
  --whatsapp-audio: #1B8755;
  --pdf-viewer-bg: #F2F2F2;
  --pdf-viewer-btn: #FBFBFB;
  --pdf-viewer-btn-hover: #F6F6F6;

  // Others
  --so-sms-color: #289c91;
  --so-dashed-border: var(--so-neuter);
  --so-tab-text-color: var(--so-neuter);
  --so-disable-tab-border: var(--so-neuter-light);
  --so-timelime-background: var(--so-light-white);
  --so-check-circle-ia: var(--so-gray-subtle);
  --so-bubble-warning-bg: #FFFEC4;
  --so-inbound-bot-bg: #ececec;
  --so-selected-table-row: #d9dfec;
}
/* Dark theme */
[data-theme="dark"] {
  --so-primary: #4852af;
  --so-primary-subtle: #4852af;
  --so-primary-thick: #000152;
  --so-secondary: #a3bd36;
  --so-secondary-subtle: #d7f068;
  --so-secondary-soft-thick: #7e9d04;
  --so-secondary-thick: #718d00;
  --so-neuter: #272d3b;
  --so-neuter-subtle: rgb(197 197 197 / 25%);
  --so-neuter-thick: #c5c5c5;
  --so-danger: #f44336;
  --so-danger-subtle: #ff7961;
  --so-danger-thick: #ba000d;
  --so-warning: #fdd835;
  --so-warning-subtle: #ffff6b;
  --so-warning-thick: #c6a700;
  --so-success: #64dd17;
  --so-success-icon: #9cff57;
  --so-success-subtle: #9cff57;
  --so-success-thick: #1faa00;
  --so-pink: #ef00b7;
  --so-purple: #570df8;
  --so-orange: #f38c15;
  --so-gray: #44444f;
  --so-gray-soft: #202229;
  --so-gray-subtle: #4a4a57;
  --so-gray-thick: #858c92;
  --so-gray-thick-soft: #b8b8b8;
  --so-gray-thick-hard: #585555;
  --so-green: #29a69a;
  --so-green-subtle: #2aa69a;
  --so-info: #00a3eb;
  --so-info-subtle: #399bc6;
  --so-white: #ffffff;
  --so-black: #000000;
  --so-blue: #002A7F;
  --so-blue-light: #41b9f0;
  --so-yellow-subtle: #5D644A;
  --so-dark: #23262d;
  --so-striped: #2b2b2b;
  --so-striped-thick: #383838;
  --so-border-left: var(--background);
  --so-green-whatsapp: #D9FDD3;
  --so-light-white: #F4F4F4;
  --so-light-black: #44444F;
  --so-disabled-input: #4A4A4A;
  --so-placeholder-input: #6e6e6e;

  //sidebar
  --so-sidebar: #22252C;
  --so-sidebar-items: var(--so-white);
  --so-hover-sidebar: var(--so-soft-blue);

  // LIGHT
  --so-primary-light: rgb(0 42 127 / 15%);
  --so-secondary-light: rgb(163 189 54 / 15%);
  --so-danger-light: rgb(244 67 54 / 15%);
  --so-warning-light: rgb(253 216 53 / 15%);
  --so-success-light: rgb(100 221 23 / 15%);
  --so-light-grey: #1c1e22;
  --so-gradient-percentage: 80%;

  // GENERAL STYLES
  --background: #0d0f11;
  --background-light: #1e2227;
  --background-card-ia: var(--background-card);
  --background-invert: var(--so-white);
  --background-card: #1a1d22;
  --color-icon:#a3bd36;
  --color-icon-inverse:#a3bd36;
  --text: var(--so-white);
  --box-shadow: 0px 3px 6px #000;
  --box-shadow-nav-var: 10px 10px 20px 15px rgba(0, 0, 0, 0.58);
  --box-shadow-intern-cards: 0px 0px 5px var(--so-neuter);
  --so-black-opacity-50: var(--background-invert);
  --navbar-bg: var(--background);

  // Badges
  --backgound-badge-success: var(--so-success-thick);
  --text-badge-success: var(--so-white);
  --backgound-badge-warning: var(--so-warning-thick);
  --text-badge-warning: var(--so-white);
  --backgound-badge-danger: var(--so-danger);
  --text-badge-danger: var(--so-white);
  --backgound-badge-primary: var(--so-primary);
  --text-badge-primary: var(--so-white);
  --backgound-badge-black: var(--so-white);
  --text-badge-black: var(--so-black);
  --backgound-badge-blue-light: var(--so-blue);
  --text-badge-black-blue-light: var(--so-white);
  --card-border-medium: var(--so-white);
  //forms
  --text-input: var(--so-white);
  --background-input: #323232;
  --card-border: transparent;
  --multi-select: var(--so-primary-opacity-50);
  --multi-select-text: var(--so-neuter-thick);

  // Popovers
  --so-popover-bg: #2E3B4E;

  // Brands
  --gm-blue-text: #30beed;
  --whatsapp-primary: #1a1d22;;
  --whatsapp-secondary: #FFFFFF;
  --whatsapp-subtle: #99f39b;
  --whatsapp-bg: #1B2C34;
  --whatsapp-received: #1F2C33;
  --whatsapp-received-system: #1F2C33;
  --whatsapp-sended: #054640;
  --whatsapp-bar-actions: #AEBAC1;
  --whatsapp-audio: #0F5D3A;
  --pdf-viewer-bg: #2E2E2E;
  --pdf-viewer-btn: #3A3A3A;
  --pdf-viewer-btn-hover: #555555;

  //Others
  --so-sms-color: #289c91;
  --so-dashed-border: var(--so-gray-thick);
  --so-tab-text-color: var(--so-neuter-subtle);
  --so-disable-tab-border: var(--background-card);
  --so-timelime-background: var(--background-input);
  --so-check-circle-ia: #f0f0f0;
  --so-selected-table-row: #d9dfec;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  label,
  app-so-replace,
  button {
    color: var(--text) !important;
  }

  .placeholder {
    background-color: var(--so-neuter);
  }

  input[type="date"],
  input[data-field="date"]{
    background-image: url(/assets/img/mdi-event_note-secondary.svg) !important;
  } 

}

/* *************************** PRIMARY *************************** */
.so-primary {
  color: var(--so-primary) !important;
}

.so-bg-primary {
  background: var(--so-primary) !important;
}

.so-bg-primary-white {
  background: var(--so-primary) !important;
  color: var(--so-white) !important;
}

.so-bg-primary-light {
  background: var(--so-primary-light) !important;
  color: var(--so-primary-thick) !important;
}

.so-bg-primary-thick-white {
  background: var(--so-primary-thick) !important;
  color: var(--so-white) !important;
}

/* *************************** SUCCESS *************************** */
.so-success {
  color: var(--so-success) !important;
}

.so-bg-success {
  background: var(--so-success) !important;
}

.so-bg-success-white {
  background: var(--so-success) !important;
  color: var(--so-white) !important;
}

.so-bg-success-light {
  background: var(--so-success-light) !important;
  color: var(--so-success-thick) !important;
}

.so-bg-success-thick-white {
  background: var(--so-success-thick) !important;
  color: var(--so-white) !important;
}

/* *************************** SECONDARY *************************** */
.so-secondary {
  color: var(--so-secondary) !important;
}

.so-bg-secondary {
  background: var(--so-secondary) !important;
}

.so-bg-secondary-white {
  background: var(--so-secondary) !important;
  color: var(--so-white) !important;
}

.so-bg-secondary-light {
  background: var(--so-secondary-light) !important;
  color: var(--so-secondary-thick) !important;
}

.so-bg-secondary-thick-white {
  background: var(--so-secondary-thick) !important;
  color: var(--so-white) !important;
}

/* *************************** DANGER *************************** */
.so-danger {
  color: var(--so-danger) !important;
}

.so-bg-danger {
  background: var(--so-danger) !important;
}

.so-bg-danger-white {
  background: var(--so-danger) !important;
  color: var(--so-white) !important;
}

.so-bg-danger-light {
  background: var(--so-danger-light) !important;
  color: var(--so-danger-thick) !important;
}

.so-bg-danger-thick-white {
  background: var(--so-danger-thick) !important;
  color: var(--so-white) !important;
}

/* *************************** WARNING *************************** */
.so-warning {
  color: var(--so-warning) !important;
}

.so-bg-warning {
  background: var(--so-warning) !important;
}

.so-bg-warning-white {
  background: var(--so-warning) !important;
  color: var(--so-white) !important;
}

.so-bg-warning-light {
  background: var(--so-warning-light) !important;
  color: var(--so-warning-thick) !important;
}

.so-bg-warning-thick-white {
  background: var(--so-warning-thick) !important;
  color: var(--so-white) !important;
}

/* *************************** BLACK *************************** */
.so-black {
  color: var(--so-black) !important;
}

.so-bg-black {
  background: var(--so-black) !important;
}

.so-bg-black-white {
  background: var(--background-invert) !important;
  color: var(--background-card) !important;
}

/* *************************** WHITE *************************** */
.so-white {
  color: var(--so-white) !important;
}

.so-bg-white {
  background: var(--so-white) !important;
}
/* *************************** Neuter *************************** */
.so-neuter-thick {
  color: var(--so-neuter-thick) !important;
}
/* *************************** Gray *************************** */

.so-bg-gray{
  background: var(--so-gray) !important;
  color: var(--background-invert);
}
.so-neuter{
  color: var(--so-neuter) !important;
}

.so-neuter-subtle {
  color: var(--so-neuter-subtle) !important;
}
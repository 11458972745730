:root {
  --so-title-super: normal normal bold 77px/94px Montserrat;
  --so-title: normal normal bold 24px/32px Montserrat;
  --so-subtitle: normal normal bold 22px/32px Montserrat;
  --so-text-1: normal normal normal 20px/24px Montserrat;
  --so-text-1-bold: normal normal bold 20px/24px Montserrat;
  --so-text-1-medium: normal normal 500 20px/24px Montserrat;
  --so-text-2: normal normal normal 16px/14px Montserrat;
  --so-text-2-bold: normal normal bold 16px/14px Montserrat;
  --so-text-3: normal normal normal 14px/20px Montserrat;
  --so-text-3-bold: normal normal bold 14px/20px Montserrat;
  --so-text-3-medium: normal normal 500 14px/20px Montserrat;
  --so-text-4: normal normal normal 12px/21px Montserrat;
  --so-text-4-bold: normal normal bold 12px/21px Montserrat;
  --so-text-5: normal normal normal 10px Montserrat;
  --so-text-5-bold: normal normal bold 10px Montserrat;
}

.so-title-super {
  font: var(--so-title-super) ;
}

.so-title {
  font: var(--so-title) ;
}

.so-subtitle {
  font: var(--so-subtitle) ;
}

.so-text-1 {
  font:var(--so-text-1) ;
}

.so-text-1-bold {
  font: var(--so-text-1-bold);
}

.so-text-1-medium {
  font: var(--so-text-1-medium);
}

.so-text-2 {
  font:var(--so-text-2);
}

.so-text-2-bold {
  font: var(--so-text-2-bold);
}

.so-text-3 {
  font:var(--so-text-3);
}

.so-text-3-bold {
  font: var(--so-text-3-bold);
}

.so-text-4 {
  font:var(--so-text-4);
}

.so-text-4-bold {
  font: var(--so-text-4-bold);
}

.so-text-5 {
  font: var(--so-text-5);
}

.so-text-5-bold {
  font: var(--so-text-5-bold);
}

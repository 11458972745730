.mat-step-icon-selected{
    background-color: var(--so-primary) !important;
}

.mat-vertical-stepper-header .mat-step-icon{
    background-color: var(--so-secondary) !important;
}

.mat-step-text-label{
    font-family: Montserrat;
    font-weight: 500;
}
@import '~material-icons/iconfont/material-icons.css';
@import 'assets/scss/colors.scss';
@import 'assets/scss/fonts.scss';
@import 'assets/scss/material/mat-select.scss';
@import 'assets/scss/material/mat-stepper.scss';
@import 'assets/scss/material/mat-modal.scss';

//GENERAL
body,* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  background-color: var(--background);
}

.container-Seekop {
  max-width: 100%;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 8rem;
  margin-bottom: 1.25rem;
}

.container-Seekop section {
  width: 100%;
}

.center-element {
  margin: 0 auto !important;
}

button {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.app-so-input-border{
  border: 1px solid var(--card-border);
}

// MODALS
.mat-mdc-dialog-surface {
  overflow-y: hidden !important;
  overflow-x: hidden;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--background-card) !important;
}

// MEDIA QUERIES
@media (max-width: 1600px) {
  .container-Seekop {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
@media (max-width: 1000px) {
  .container-Seekop {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
// SCROLL
/* Ancho de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--so-neuter);
  border-radius: 4px;
}
/* Fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
